import React  from "react";
// { useState, useLayoutEffect } 
import { graphql } from "gatsby";
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { OutboundLink } from "gatsby-plugin-google-gtag"
import Layout from "../components/layout";
import SEO from "../components/seo";


// import getBlog, { formatDate } from "../common/blog.js";

import "../styles/styles.css";

const TeamPage = (context) => {
  
  return (
    <Layout>
      <SEO title="Team" />
      <div className="page-container">
          <h1>Meet the team</h1>
          {
            context.data.allAirtable.nodes.map((bio) => {
              return <div key={bio.id} className={"teammate-container"}>
                <div className={"image"}>
                  {bio.data.TeammatePhoto &&
                  <img
                    alt={`${bio.data.TeammateName}`}
                    src={bio.data.TeammateSitePhoto || ''} />
                  }
                </div>
                <div className="bio">
                  <h1 id={bio.data.TeammateName.split(" ").join("-")}>
                    {bio.data.TeammateName}
                  </h1>
                  <p><strong>{bio.data.TeammateRole}</strong></p>
                  {/* {
                    bio.data.TeammateCategory.map((category) => <span className="category">{category}</span>)
                  } */}
                  {bio.data.TeammateEmail && <p><OutboundLink href={`mailto:${bio.data.TeammateEmail}`}>{bio.data.TeammateEmail}</OutboundLink  ></p>}
                  {bio.data.TeammateBio && <MDXRenderer>{bio.data.TeammateBio.childMdx.body}</MDXRenderer>}
                </div>
              </div>
            })
          }
          <h2 className="team-subtitle">Alumni, Contributors and Acknowledgements</h2>
          <div>
            <p>Numerous contributors have contributed to the success of the Princeton Gerrymandering Project, including but not limited to:</p>
            <p>Brian Remlinger was the first full-time staff member of the Gerrymandering Project. Brian did the first wave of analysis, co-wrote scholarly and popular articles, and played a key role in launching our current activities.</p>
            <p>Rob Whitaker designed an earlier verison of our website, including the geographic test explorer.</p>
            <p>Madeleine Parker bolstered our GIS capabilities.</p>
            <p>Aimee Otsu helped improve the user interface of the website.</p>
            <p>Sung Chang did early work on the map visualizer.</p>
            <p>Tim Mack compiled and cleaned our election data.</p>
            <p>John O'Neill assisted with our data collection process and drew some political maps.</p>
            <p>Laura Williamson strengthened our efforts at outreach and partnership with reformers.</p>
            <p>Will Adler conducted statistical analyses, maintained datasets and software tools, and worked with state-level partners.</p>
            <p>Ben Williams conducted legal analyses, drafted statutes, drew maps, and maintained strategic partnerships.</p>
            <p>Lafayette College designed our bug logo.</p>
            <p>Hannah Wheelen founded the data group and coordinated its work through the 2021 redistricting cycle. Hannah and Amanda Kmetz developed the methods underlying draft map evaluation and report cards.</p>
            <p>Indraneel Purohit was technology developer and developed many of the backend tools that power this site.</p>
            <p>Hope Johnson and Baxter Demers were data analysts.</p>
            <p>Steve Birnbaum did business development.</p>
            <p>Zachariah Sippy '23 performed state-by-state political analysis.</p>
            <p>Michaela Daniel '22 did outreach for Representable.</p>
            <p>Jason Rhode worked on communications and outreach.</p>
            <p>Jesse T. Clark was a postdoctoral researcher. During New Jersey redistricting he was an observer and assisted PGP staff.</p>
            <p>Adam Podowitz-Thomas was senior legal strategist. Helen Brewer and Aaron Barden were legal analysts.</p>
            <p>Sandra Chen '23 did legislative policy analysis, with a focus on communities-of-interest as a standard for fair districting. She published an article in the Stanford Journal of Civil Rights and Civil Liberties.</p>
            {/*<p>Joan W. Dunlap was associate director. </p>*/}
          </div>
          <h4>For general inquiries, reach out to us here: <OutboundLink href={`mailto:gerrymander@princeton.edu`}>gerrymander@princeton.edu</OutboundLink>.</h4>
      </div>
    </Layout>
  );
};

export default TeamPage;

export const teamBiosQuery = graphql`
  query teamPageQuery {
    allAirtable(filter: {table: {eq: "Team"}}, sort: {fields: data___TeammateOrder, order: ASC}) {
      nodes {
        id
        data {
          Last_Updated
          TeammateName
          TeammateOrder
          TeammateCategory
          TeammateEmail
          TeammateRole
          TeammateBio {
            id  
            childMdx {
              body
            }
          }
          TeammateSitePhoto
          TeammatePhoto {
            localFiles {
              id
              url
            }
          }
        }
      }
    }
  }
`;
